@font-face {
	font-family: "RussianRailGPro";
	/*src: url("../fonts/RussianRailGProExtend.otf") format("opentype");*/
	/*src: url("../fonts/RussianRailGRegular.otf") format("opentype");*/
	src: url("../fonts/RussianRailGProMedium.otf") format("opentype");
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "RussianRailGPro";
	src: url("../fonts/RussianRailGProBold.otf") format("opentype");
	font-style: auto;
	font-weight: 600;
}

@font-face {
	font-family: 'Verdana';
	src: url('../fonts/Verdana-BoldItalic.woff2') format('woff2'),
	url('../fonts/Verdana-BoldItalic.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Verdana';
	src: url('../fonts/Verdana-Italic.woff2') format('woff2'),
	url('../fonts/Verdana-Italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Verdana';
	src: url('../fonts/Verdana-Bold.woff2') format('woff2'),
	url('../fonts/Verdana-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Verdana';
	src: url('../fonts/Verdana.woff2') format('woff2'),
	url('../fonts/Verdana.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}