

































































































































@import "@/assets/css/project-variables.scss";

.login {
  position: relative;
  width: 100%;
  height: calc(100vh);
  display: flex;

  &__left {
    flex-shrink: 0;
    width: 43%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 48px 40px;
    text-align: center;
  }

  &__right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/img/login-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &__logo {
    align-self: flex-start;
  }

  &__title {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5;
    color: $--color-black;
    margin: 0 0 8px;
  }

  &__subtitle {
    font-size: 24px;
    margin: 0;
    color: $--color-black;
  }

  &__links {
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-between;

    a {
      font-size: 14px;
      line-height: 1.5;
      text-decoration: underline;
      color: $--color-black;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__authorization {
    display: flex;
    padding: 40px 32px;
    background: $--color-white;
    box-shadow: 1px 0 30px 7px rgba(20, 33, 64, 0.04);
    border-radius: 10px;

    &-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 32px 0 0;
      text-align: center;
      border-right: 1px solid $--color-divider-3;
    }

    &-right {
      display: flex;
      flex-direction: column;
      padding: 0 0 0 32px;
    }

    &-title {
      margin: 0 0 4px;
      font-size: 30px;
      color: $--color-black;
    }

    &-subtitle {
      font-size: 16px;
      color: $--color-black;
    }

    &-logo {
      width: 144px;
      margin: 0 0 32px auto;
    }

    .el-form {
      min-width: 378px;
      margin: 0 0 16px;

      &-item {
        margin: 0 0 24px;

        label {
          display: block;

          & > span {
            display: inline-block;
            margin: 0 0 8px;
            font-size: 14px;
            color: $--color-text-regular-4;

            sup {
              color: $--color-secondary-red;
              font-size: 14px;
              margin: 0 2px 0 0;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .login-button {
        width: 100%;
      }
    }

    &-link {
      align-self: flex-end;
    }
  }
}

