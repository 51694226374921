







































































.test-sections {
  border: none;
  .el-collapse-item__wrap {
    background: transparent;
    border: none;
  }
  .el-collapse-item__header {
    background: transparent;
  }
}
