
























































































































































































































































































































































































.report-icon {
  font-size: 30px;

  &.el-icon-success {
    color: #68b168;
  }

  &.el-icon-error {
    color: #e96666;
  }
}

.el-table.el-table--report {

  td {
    vertical-align: middle;
  }
}
