























































































































































































.competence-mpcs-modal-body {
  & .el-row {
    margin-bottom: 20px;
  }
}
