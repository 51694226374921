





































































































































































































































































@import "@/assets/css/project-variables";

.el-table--questions {

  th.el-table__cell {
    vertical-align: bottom;
  }

  tr {
    transition: background-color .4s;

    &.expanded {
      background-color: $--color-divider-3;

      + tr td {
        background-color: $--color-divider-3;

        &:hover {
          background-color: $--color-divider-3 !important;
        }
      }
    }
  }

  td.el-table__expanded-cell {
    padding: 0;
  }

  .el-table__expand-icon.el-table__expand-icon--expanded {
    transform: none;
  }

  .el-table__expand-icon > .el-icon {
    margin-top: 4px;
    margin-left: 6px;
    transform: translate(-50%, -50%);
    transition: transform .4s;
  }

  .el-table__expand-icon.el-table__expand-icon--expanded > .el-icon {
    transform: translate(-50%, -50%) rotate(90deg);
  }
}

