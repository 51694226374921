





































































































































































































































































































.test-development {
  .all-agreed {
    color: green;
  }

  .not-agreed {
    color: red;
  }

  .el-link {
    color: #3D8BF4;
  }

}


