
























































@import "@/assets/css/project-variables.scss";

.el-popper.el-popper-table {
  padding: 16px;
  margin-top: 4px;
  line-height: 1.5;
  color: $--color-text-regular-4;
  border: none;
  background: $--color-white;
  box-shadow: 1px 0 30px 7px rgba(20, 33, 64, 0.06);
  border-radius: 5px;

  .ellipsis-menu-slot {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .el-button {

    &--text {
      color: $--color-text-regular-4;

      i {
        font-size: 16px;
      }

      &:hover {
        color: $--color-primary;
      }
    }
  }

  @media only screen and (max-width: 1480px) {
    padding: 12px;

    .el-button {

      &--text {

        i {
          font-size: 14px;
        }
      }
    }
  }
}

