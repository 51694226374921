$--color-white: #fff;
$--color-black: #1A2122;

/* Primary colors -------------------------- */
$--color-primary: #3D8BF4;
$--color-primary-light-1: mix($--color-white, $--color-primary, 10%);
$--color-primary-light-2: mix($--color-white, $--color-primary, 20%);
$--color-primary-light-3: mix($--color-white, $--color-primary, 30%);
$--color-primary-light-4: mix($--color-white, $--color-primary, 40%);
$--color-primary-light-5: mix($--color-white, $--color-primary, 50%);
$--color-primary-light-6: mix($--color-white, $--color-primary, 60%);
$--color-primary-light-7: mix($--color-white, $--color-primary, 70%);
$--color-primary-light-8: mix($--color-white, $--color-primary, 80%);
$--color-primary-light-9: mix($--color-white, $--color-primary, 90%);
$--color-primary-hover: #246FD5;

/* System colors -------------------------- */
$--color-success: #6DC077;
$--color-warning: #E18250;
$--color-danger: #D95954;
$--color-info: #0A76A4;


/* Text colors -------------------------- */
$--color-text-regular: #1A2122;
$--color-text-regular-1: mix($--color-white, $--color-text-regular, 10%);
$--color-text-regular-2: mix($--color-white, $--color-text-regular, 20%);
$--color-text-regular-3: mix($--color-white, $--color-text-regular, 30%);
$--color-text-regular-4: mix($--color-white, $--color-text-regular, 40%);
$--color-text-regular-5: mix($--color-white, $--color-text-regular, 50%);
$--color-text-regular-6: mix($--color-white, $--color-text-regular, 60%);
$--color-text-regular-7: mix($--color-white, $--color-text-regular, 70%);
$--color-text-regular-8: mix($--color-white, $--color-text-regular, 80%);
$--color-text-regular-9: mix($--color-white, $--color-text-regular, 90%);


/* Secondary colors -------------------------- */
$--color-secondary-red: #E21A1A;
$--color-secondary-gray: #F7F7F7;
$--color-secondary-red-hover: #BC0B0B;
$--color-secondary-gray-hover: #EAEAEA;

/* Divider colors -------------------------- */
$--color-divider: #D3D3D3;
$--color-divider-2: #EAEAEA;
$--color-divider-3: #F3F3F3;
$--color-divider-4: #F9F9F9;

/* Button -------------------------- */
$--button-font-size: 14px;
$--button-font-weight: 700;
$--button-border-radius: 6px;
$--button-padding-vertical: 13.5px;
$--button-padding-horizontal: 24px;
$--button-medium-font-size: 14px;
$--button-medium-border-radius: 6px;
$--button-medium-padding-vertical: 12px;
$--button-medium-padding-horizontal: 24px;

$--button-small-font-size: 14px;
$--button-small-border-radius: 6px;
$--button-small-padding-vertical: 8px;
$--button-small-padding-horizontal: 12px;

$--button-mini-font-size: 12px;
$--button-mini-border-radius: 6px;
$--button-mini-padding-vertical: 4px;
$--button-mini-padding-horizontal: 8px;

$--button-default-color: $--color-primary;
$--button-default-fill: $--color-white;

$--border-radius-base: 5px;
$--card-border-radius: 5px;
$--tag-border-radius: 5px;

/* Switch
-------------------------- */
$--switch-on-color: $--color-primary;
$--switch-off-color: $--color-divider;
$--switch-disabled-color: $--color-text-regular-6;
$--switch-disabled-text-color: $--color-text-regular-6;

$--switch-font-size: 14px;
$--switch-core-border-radius: 20px;
$--switch-width: 40px;
$--switch-height: 20px;
$--switch-button-size: 16px;

/* Select
-------------------------- */
$--select-border-color-hover: $--color-text-regular;
$--select-disabled-border: 1px solid $--color-divider;
$--select-font-size: 14px;
$--select-close-hover-color: $--color-text-regular-4;

$--select-input-color: $--color-text-regular-4;

/* Card
--------------------------*/
$--card-border-radius: 0;
$--card-padding: 0;

/* Tooltip
-------------------------- */
$--tooltip-fill: #606060;
$--tooltip-color: $--color-white;
$--tooltip-font-size: 14px;
$--tooltip-border-color: #606060;
$--tooltip-arrow-size: 6px;
$--tooltip-padding: 8px 16px;