






























































































































































































































































































































































































































































































































































































































































































































































@import "./src/assets/css/project-variables";

.fc-table {
  font-size: 14px;

  &__head {
    padding: 16px 0;
    background-color: $--color-divider-3;
    border-radius: 6px;

    &-col {
      padding: 0 16px;
    }
  }

  &__row {
    padding: 16px 0;
    margin: 0 0 16px;
    border-bottom: 1px solid $--color-divider;
  }

  &__col {
    padding: 0 16px;
  }

  .comments {
    padding-top: 24px;

    &__title {
      font-size: 16px;
      margin-bottom: 16px;
    }

    &__list {
      margin-bottom: 16px;
    }

    &__item {
      padding-bottom: 16px;
      margin-bottom: 16px;

      &-inner {
        max-width: 100%;
      }

      &-avatar {
        width: 40px !important;
        height: 40px !important;
      }

      &-text {
        margin-bottom: 4px;
      }

      &-delete {
        width: 16px;
        height: 16px;
      }

      &-name,
      &-date {
        font-size: 12px;

        + span {
          font-size: 12px;
        }
      }
    }

    .edit-block__value {

      > span {
        font-size: 14px;
      }
    }

    &__add {

      .el-input {
        margin-bottom: 8px;

        &__inner {
          padding: 8px 12px;
        }
      }

      .el-button {
        padding: 6px 12px;
        font-size: 12px;
        text-transform: none;
      }
    }
  }

  @media only screen and (max-width: 1480px) {
    font-size: 12px;

    &__head {
      padding-top: 8px;
      padding-bottom: 8px;

      &-col {
        padding-right: 8px;
        padding-left: 8px;
      }
    }

    &__row {
      padding-top: 8px;
    }

    &__col {
      padding-right: 8px;
      padding-left: 8px;
    }

    &-element-area {
      font-size: 16px !important;
    }

    .fc-table-element-other {
      font-size: 14px;
    }

    .comments__item-name {
      font-size: 10px;
    }

    .comments {
      padding-top: 16px;

      &__title {
        font-size: 14px;
        margin-bottom: 8px;
      }

      &__list {
        margin-bottom: 8px;
      }

      &__item {
        padding-bottom: 8px;
        margin-bottom: 8px;

        &-avatar {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }
  }
}

.bordered {
  border: 1px solid $--color-divider;
  border-radius: 6px;
}

.indent {
  padding: 4px;
  margin: 4px;
}

.fc-table-header {
  background-color: #F2F2F2;
  color: #797979;
  padding: 10px 10px;
}

.fc-table-area-row {
  margin-top: 10px;
}

.fc-table-element {
  padding: 6px;
  margin-bottom: 6px;
}

.fc-table-element-area {
  font-size: 20px;
}

.fc-table-element-function {
  font-size: 16px;

  @media only screen and (max-width: 1480px) {
    font-size: 12px;
  }
}

.fc-table-element-other {
  font-size: 16px;

  .edit-block__value {

    > span {
      max-width: 80%;
      margin-right: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.fc-table .has-delete-button {
  position: relative;

  .edit-block__value {

    span {
      margin-right: 16px;

      @media only screen and (max-width: 1480px) {
        margin-right: 12px;
      }
    }

    svg {
      margin-top: 4px;
      margin-right: 24px;
    }
  }

  &--min {

    .edit-block__value {

      svg {
        margin-top: 6px;
      }
    }

    .delete-button {
      top: 4px;
      right: 0px;
    }
  }
}

.fc-table .has-delete-button:hover .delete-button {
  display: unset;
}

.fc-table .delete-button {
  position: absolute;
  top: 8px;
  right: 0px;
  padding: 0;
  display: none;
  /*color:black;*/
}

.page {

  &__head {

    .click-to-edit-div {

      .edit-block__value  svg {
        display: none;
      }
    }
  }
}

