






































































































































































































@import "@/assets/css/project-variables.scss";

.el-dialog {

  &--edit-project {
    padding: 32px 40px 40px;
    background: $--color-white;
    box-shadow: 1px 0 30px 7px rgba(20, 33, 64, 0.02);
    border-radius: 10px;

    .el-dialog {

      &__header {
        padding: 0;
        margin-bottom: 4px;
      }

      &__title {
        font-size: 30px;
        font-weight: 400;
        line-height: 1.5;
        color: $--color-text-regular;
      }

      &__body {
        padding: 0;
      }

      &__footer {
        display: flex;
        align-items: center;
        padding: 0;

        .el-button {
          width: calc(50% - 12px);
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
            margin-left: 0;
          }
        }
      }
    }

    .desc {
      margin-bottom: 32px;
      font-size: 14px;
      text-align: center;

      .el-link {
        color: $--color-primary;

        &:hover {
          color: $--color-primary-hover;
        }
      }
    }

    .el-row {
      margin-bottom: 32px;
    }

    .el-form {

      &-item {
        margin: 0 0 24px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .el-select,
    .el-input {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1480px) {

    &--edit-project {

      .el-dialog__title {
        font-size: 20px;
      }

      .desc {
        font-size: 12px;
        margin-bottom: 24px;
      }

      .el-form-item {
        margin-bottom: 16px;
      }
    }
  }
}
