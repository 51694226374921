

















































































































































































































































































































































































































































































































































































































































































































































































































































































































@import "./src/assets/css/project-variables";

.library-link {
  display: inline-flex;
  align-items: center;

  svg {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 8px 0 0;
  }
}

.profiling-checkbox {
  height: 100%;
  display: flex;
  align-items: flex-end;

  .el-checkbox {
    margin-bottom: 12px;
  }
}

.content {
  position: relative;
}

.table-profiling {
  margin: 0 0 56px 0;
}

.table-settings {
  margin-bottom: 16px;

  &__btns {
    display: flex;
    align-items: center;

    .el-button:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.el-table {

  th.el-table__cell--select {
    padding: 24px 0 12px;

    .cell {
      padding: 0;
    }
  }

  td.el-table__cell--select {
    vertical-align: middle;
  }

  th.el-table__cell--bottom {
    vertical-align: bottom;
  }

  &--profiling {
    border-right: 1px solid $--color-divider;

    .el-table__fixed {
      border-right: 1px solid $--color-divider;
    }

    .table-head {
      display: flex;
      flex-direction: column;

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px 24px;
        border-bottom: 1px solid $--color-divider;

        .el-button {
          padding: 8px;
          font-weight: 400;
          text-transform: none;
        }
      }

      &__responsible {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 12px 16px;
      }

      &__status {
        padding: 0 12px 16px;
        color: $--color-primary;
      }

      &__title {
        padding: 0 12px 0;
        margin-bottom: 0;
        font-size: 14px;
        color: $--color-primary;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .cell.el-tooltip {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: unset;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      box-orient: vertical;
    }

    .el-button {

      i {
        margin-right: 0;
      }
    }
  }
}

.el-select.el-select-profiling {

  .el-input.el-input--suffix .el-input__suffix {
    right: 8px;
    width: 18px;
    height: 18px;
  }

  .el-input.el-input--suffix .el-input__suffix .el-input__icon::before {
    font-size: 16px;
  }

  .el-input__inner {
    padding: 4px 28px 4px 8px;
  }
}

.el-select-dropdown {

  &--profiling {

    .el-select-dropdown__item:first-child {
      height: 48px;
    }
  }
}


.profiling-select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border-color: #3D8BF4;
  background-color: white;

  &:disabled{
    border-color: #c0c0c0;

    &:hover {
      cursor: not-allowed;
    }
  }
}
.expired-tsd{
  background-color: #ffd9d9 !important;
}
.new-tsd{
  background-color: #e3ffd9 !important;
}
