




















































































































































































































































































































































































































@import "./src/assets/css/project-variables";

.el-link {
  white-space: normal;
  color: $--color-primary;
  transition: all .4s;
}

.password-info {

  p {
    margin-bottom: 12px;
    font-size: 12px;
  }

  ul {
    padding-left: 18px;
    margin-bottom: 0;
    font-size: 12px;
    list-style: circle;

    li:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.profile {

  &__title {
    margin-bottom: 0;
  }

  &__subtitle {
    font-weight: 400;
    margin-bottom: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    padding: 32px;
    background: $--color-divider-4;
    border-radius: 6px;

    &-title {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 24px;
      text-align: center;
    }

    .el-avatar {
      margin: 0 auto 24px;
    }

    &-prompt {
      font-size: 14px;
      color: $--color-text-regular-4;
      margin-bottom: 32px;
      text-align: center;
    }
  }

  &__settings {

    &-item {
      display: flex;
      padding: 0 0 16px;
      margin: 0 0 24px;
      border-bottom: 1px solid $--color-divider;

      &__img {
        width: 48px;
        height: 48px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 16px 0 0;
        background: $--color-white;
        box-shadow: 0px 0px 30px 7px rgba(20, 33, 64, 0.02);
        border-radius: 8px;

        img, i {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        i {
          color: $--color-primary;
        }
      }

      &__inner {
        flex: 1;
      }

      &__title {
        margin-bottom: 4px;
        font-size: 14px;
        color: $--color-text-regular-4;
      }

      .edit-block {

        .edit-block__value {

          > span {
            width: 280px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > svg {
            width: 24px;
            height: 24px;
            margin: 0;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }

    .el-button {
      width: 100%;
      margin-bottom: 40px;
      font-weight: 400;
      text-transform: none;
    }
  }

  .el-form {

    &-item {

      &__label {
        margin-bottom: 8px;
      }
    }
  }

  @media only screen and (max-width: 1480px) {

    &__info {
      padding: 16px;

      &-title {
        margin-bottom: 16px;
        font-size: 20px;
      }

      .el-avatar {
        width: 80px !important;
        height: 80px !important;
        margin-bottom: 16px;
        line-height: 80px !important;
      }

      &-prompt {
        margin-bottom: 16px;
        font-size: 12px;
      }
    }

    &__settings {

      &-item {
        padding-bottom: 8px;
        margin-bottom: 16px;

        &__img {
          width: 32px;
          height: 32px;
          margin-right: 8px;

          img {
            width: 16px;
            height: 16px;
          }
        }

        &__inner {
          font-size: 12px;
        }

        &__title {
          margin-bottom: 2px;
          font-size: 12px;
        }

        .edit-block .edit-block__value > span {
          width: 220px;
          font-size: 12px;
        }

        .edit-block .edit-block__value > svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

