











































































































































































































































































































































































































































































































@import "@/assets/css/project-variables.scss";

.el-link {
  white-space: normal;
  color: $--color-primary;
  transition: all .4s;

  &:hover {
    text-decoration: underline;
  }
}
