






























































































































































































































@import "./src/assets/css/project-variables";

.info {
  background-color: #F2F0F0;
  padding: 15px;
}

.file-example {
  display: flex;
  align-items: center;
  margin: 16px 0;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
    margin-right: 8px;

    path {
      transition: all .4s;
    }
  }

  &:hover {

    svg {

      path {
        stroke: $--color-primary;
      }
    }
  }
}

.file-add {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background: $--color-white;
  border: 1px solid $--color-divider;
  border-radius: 6px;
  cursor: pointer;
  transition: all .4s;

  &__icon {
    width: 40px;
    height: 40px;
    margin-bottom: 8px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__input {
    display: none;
  }

  &__title {
    margin-bottom: 4px;
  }

  &__desc {
    color: $--color-text-regular-4;
  }

  &:hover {
    border-color: $--color-text-regular;
  }

  &:focus {
    border-color: $--color-primary-light-6;
  }

  @media only screen and (max-width: 1480px) {
    font-size: 12px;

    &__icon {
      width: 24px;
      height: 24px;
    }
  }
}

