





























































































































































































































.el-table.el-table--custom {

  .el-table__header,
  .el-table__body,
  .el-table__footer {
    border-collapse: collapse;
  }

  td {
    vertical-align: middle;
    border: 1px solid #D3D3D3;
    background-color: transparent;

  }
}
