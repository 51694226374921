

















































































@import "./src/assets/css/project-variables";

.back-button {
  vertical-align: top;
  margin-right: 15px;
  color: #616161;
  background-color: #F2F2F2;
  border: none
}

.page {

  &__head {
    margin-bottom: 24px;

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .edit {
      width: 24px;
      height: 24px;
      margin-left: 8px;
      cursor: pointer;

      svg {
        display: block;
        width: 100%;
        height: auto;

        path {
          transition: all .4s;
        }
      }

      &:hover {

        svg {

          path:first-of-type {
            fill: $--color-primary;
          }

          path:last-of-type {
            stroke: $--color-primary;
          }
        }
      }
    }

    .edit-block {
      width: 100%;
      min-width: 900px;
    }
  }

  @media only screen and (max-width: 1480px) {

    &__head {
      margin-bottom: 16px;

      &-title {
        font-size: 20px;
      }
    }
  }
}
