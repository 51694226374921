









































































































































































@import "./src/assets/css/project-variables";

.image-container {
  display: flex;
  align-items: center;

  &__name {
    margin-right: 12px;
    color: $--color-text-regular-4;
  }

  &__delete {
    width: 24px;
    height: 24px;
    cursor: pointer;

    svg {

      path {
        transition: stroke .4s;
      }
    }

    &:hover {

      svg {

        path {
          stroke: $--color-danger;
        }
      }
    }
  }
}

.el-upload-list--picture-card  {

  .el-upload-list__item {
    width: auto;
  }

  .el-upload-list__item-actions {
    top: 50%;
    left: 2px;
    width: 78px;
    height: 44px;
    border-radius: 4px;
    transform: translateY(-50%);

    .el-upload-list__item-preview {
      width: 100%;
      height: 100%;
    }

    &:hover {

      .el-upload-list__item-preview {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.image-preview {
  max-height: 740px;
  padding: 40px;
  box-shadow: 1px 0 30px 7px rgba(20, 33, 64, 0.02);
  border-radius: 10px;

  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__body {
    width: 100%;
    height: 100%;
  }

  &__img {
    width: fit-content;
    height: 480px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

