














































































































@import "./src/assets/css/project-variables";

.header {
  z-index: 20;
  position: sticky;
  top: 0;
  background-color: $--color-white;

  &__logo {
    flex-shrink: 0;
    width: 143px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 100px;
  }

  &__menu {
    margin-right: auto;
  }

  &__account {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin: 0 16px 0 0;

    &-bell {
      position: relative;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      .attention {
        position: absolute;
        top: -4px;
        right: 0;
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        background-color: $--color-secondary-red;
        border: 2px solid $--color-white;
        border-radius: 50%;
      }

      &.active {
        cursor: pointer;
      }
    }

    &-avatar {
      width: 56px;
      height: 56px;
      margin: 0 16px 0 0;
      border: 2px solid $--color-text-regular-6;
      border-radius: 50%;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }

    &-name {
      font-size: 14px;
      font-weight: 700;
    }

    &-position {
      font-size: 12px;
      color: $--color-text-regular-4;
    }

    &-link {
      font-size: 14px;
      color: $--color-primary;
    }
  }

  &__logout {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      display: block;
      height: auto;

      path {
        transition: all .4s;
      }
    }

    &:hover {

      svg {

        path {
          stroke: $--color-primary;
        }
      }
    }
  }

  .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
    border-bottom: none;
  }

  @media only screen and (max-width: 1600px) {

    &__logo {
      width: 120px;
      height: auto;
      margin-right: 60px;
    }
  }

  @media only screen and (max-width: 1480px) {

    &__logo {
      margin-right: 32px;
    }

    &__account {
      margin-right: 12px;

      &-avatar {
        width: 48px;
        height: 48px;
      }

      &-name, &-link {
        font-size: 12px;
      }
    }

    &__logout {
      width: 16px;
      height: 16px;
    }
  }
}

//@media only screen and (max-width: $screen-md-min) {
//
//    .logo-header {
//        padding: 0;
//    }
//
//    #toggle {
//        visibility: visible;
//        opacity: 1;
//        margin-top: 6px;
//    }
//
//    #toggle .span {
//        height: 4px;
//        margin: 5px 0;
//        transition: all .3s ease-out;
//        backface-visibility: visible;
//        visibility: visible;
//        opacity: 1;
//    }
//
//    #menu .el-menu-item {
//        display: none;
//    }
//
//    #menu.active {
//        margin: 70px 0;
//        visibility: visible;
//        opacity: 0.98;
//        transition: all .5s ease-out;
//
//    }
//}
