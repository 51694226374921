










































































































































































































@import "./src/assets/css/project-variables";

.comments {
  padding: 40px 0 0;
  border-top: 1px solid $--color-divider;

  &__title {
    margin: 0 0 32px 0;
    font-size: 36px;
  }

  &__list {
    margin: 0 0 40px;
  }

  &__item {
    display: flex;
    padding: 0 0 32px;
    margin: 0 0 24px;
    border-bottom: 1px solid $--color-divider;

    &-avatar {
      flex-shrink: 0;
      margin: 0 8px 0 0;
    }

    &-inner {
      flex: 1;
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &-text {
      margin: 0 0 8px;

      .edit-block__value > span {
        width: 90%;
        font-size: 16px;
        line-height: 1.5;
        color: $--color-text-regular;
      }
    }

    &-information {
      font-size: 14px;
      color: $--color-text-regular-4;
    }

    &-delete {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      margin-left: auto;

      button {
        svg {
          width: 100%;
          height: 100%;

          path {
            transition: all .4s;
          }
        }

        &:hover {

          svg {

            path {
              stroke: $--color-danger;
            }
          }
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__add {

    .el-input {
      margin-bottom: 24px;
    }
  }
}

