





























































@import "./src/assets/css/project-variables";

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.table-question-answer {

  td:first-child,
  th:first-child {
    padding-left: 24px;
  }

  .el-table__header-wrapper,
  .el-table__body-wrapper {
    background-color: $--color-divider-3;
  }

  th.el-table__cell {
    background-color: $--color-divider-3;
    border: none;
  }

  .el-table__row {
    pointer-events: none;

    td {
      padding: 0;

      .cell {
        padding: 0;

        div {
          padding: 12px;
        }
      }

      &:first-child {
        padding-left: 24px;
      }
    }

    &.is-right {

      td {
        background: #dfffdf;
      }
    }

    &:last-child {

      td {
        border-bottom: none;
      }
    }
  }
}
