


































































































































































@import "./src/assets/css/project-variables";

.test-container {
  padding-bottom: 16px;
  border-bottom: 1px solid $--color-divider;

  .section-container {
    margin: 0 0 16px;

    .el-collapse {
      border: 1px solid $--color-divider;
      border-radius: 6px;

      &-item {
        position: relative;
        padding-left: 8px;
        margin: 0;

        &__header {
          height: auto;
          flex-direction: row-reverse;
          justify-content: flex-end;
          padding: 20px;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          border: none;
        }

        &__arrow {
          position: relative;
          width: 24px;
          height: 24px;
          margin-left: 0;
          transform: none;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-image: url("../../../assets/img/icons/icon-plus.svg");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transition: all .4s;
          }

          &.is-active {

            &::before {
              background-image: url("../../../assets/img/icons/icon-minus.svg");
            }
          }
        }

        &__wrap {
          border-bottom: none;
        }

        &__content {
          padding-bottom: 24px;
          font-size: 14px;
          line-height: 1.5;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 8px;
          height: 100%;
          background-color: $--color-divider-2;
        }
      }
    }
  }
  .lower-parameter{
    color: red;
  }
  .average-parameter {
    color: orange;
  }
  .high-parameter {
    color: green;
  }
}



