











































































































































































































































































































































































































@import "./src/assets/css/project-variables";

.settings {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__right {
    margin-left: auto;
  }
}

.MPC-table .no-hover:hover > td {
  //background-color: initial !important;
}

.MPC-table td {
  vertical-align: top !important;
}

.MPC-table .dndrop-draggable-wrapper {
  overflow: unset !important;

  > .el-row {
    padding-bottom: 16px;
  }
}

.MPC-table tr.el-table__row {
  height: 1px;
}

.MPC-table td.el-table__cell {
  height: inherit;
}

.MPC-table div.cell {
  height: 100%;
  //background-color: red;
}

.indicator-block {
  position: relative;
  border-radius: 6px;
  border: 1px solid $--color-white;
  padding: 8px 16px;
  background-color: $--color-white;
  cursor: pointer;

  .el-row {
    position: static;

    .edit-block__value {

      svg {
        position: absolute;
        top: 50%;
        right: 40px;
        margin-top: 0;
        transform: translateY(-50%);
      }
    }
  }
}

.indicator-block-drag-handle {
  position: absolute;
  top: 50%;
  left: -12px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: move;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: all .4s;

  svg path {
    transition: stroke .4s;
  }

  &:hover {

    svg path {
      stroke: #246FD5;
    }
  }
}

.competence-indicators {

  .edit-block__value {
    width: 95%;
  }
}

.competence-block-drag-handle {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: move;

  svg path {
    transition: stroke .4s;
  }

  &:hover {

    svg path {
      stroke: #246FD5;
    }
  }
}

.indicator-block .delete-button {
  position: absolute;
  top: 50%;
  right: 6px;
  padding: 0;
  display: block;
  color: black;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: all .4s;
}

.indicator-block:hover {

  .indicator-block-drag-handle,
  .delete-button {
    opacity: 1;
    pointer-events: all;
  }
}

.MPC-table.el-table {

  th.el-table__cell {
    padding: 16px 0;
    border-top: none;

    .cell {
      padding: 0 16px;
    }
  }

  td.el-table__cell {

    .cell {
      padding: 0 16px;
    }
  }

  .MPC-table__users {

    > div:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .comments {
    padding-top: 24px;

    &__title {
      font-size: 18px;
      margin-bottom: 16px;
    }

    &__list {
      margin-bottom: 16px;
    }

    &__item {
      padding-bottom: 16px;
      margin-bottom: 16px;

      &-avatar {
        width: 40px !important;
        height: 40px !important;
      }

      &-text {
        margin-bottom: 4px;
      }

      &-delete {
        width: 16px;
        height: 16px;
      }

      &-name,
      &-date {
        font-size: 12px;

        + span {
          font-size: 12px;
        }
      }
    }

    .edit-block__value {

      > span {
        font-size: 14px;
      }
    }

    &__add {

      .el-input {
        margin-bottom: 8px;

        &__inner {
          padding: 8px 12px;
        }
      }

      .el-button {
        padding: 6px 12px;
        font-size: 12px;
        text-transform: none;
      }
    }
  }

  .sources {
    margin-bottom: 8px;
  }
}

.competence-indicators {
  padding-left: 12px;
  margin-bottom: 8px;

  .edit-block {
    width: 100%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.mpc-collapse-custom {
  border-top: none;
  border-bottom: none;

  .el-collapse-item {
    margin-bottom: 16px;

    &__header {
      height: auto;
      min-height: 48px;
      padding: 16px;
      margin-bottom: 8px;
      line-height: 1.5;
      color: $--color-text-regular;
      border-radius: 6px;
      background-color: $--color-divider-3;
      border-bottom: none;
    }

    .collapse-plus-button {
      margin-right: 8px;
      color: $--color-primary;
    }

    .edit-block {
      width: 90%;
    }
  }

  @media only screen and (max-width: 1480px) {

    .el-collapse-item {
      margin-bottom: 8px;

      &__header {
        padding: 8px;
        margin-bottom: 4px;
        font-size: 14px;
      }

      .collapse-plus-button {
        margin-right: 4px;
      }

      .edit-block {
        width: 85%;
      }
    }
  }
}

