
























































































































































































































































@import "./src/assets/css/project-variables";

.edit-block {

  &__value {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    span {
      margin: 0 8px 0 0;
    }

    svg {
      flex-shrink: 0;
      margin: 2px 0 0;

      path {
        transition: all .4s;
      }

      &:hover {

        path:first-of-type {
          fill: $--color-primary;
        }

        path:last-of-type {
          stroke: $--color-primary;
        }
      }
    }

    .placeholder {
      color: $--color-text-regular-4;
    }
  }

  &--no-svg {

    .edit-block__value svg {
      display: none;
    }
  }

  @media only screen and (max-width: 1480px) {
    span {
      font-size: 14px;
    }
  }
}

.click-to-edit-div.click-to-edit-enabled:hover {
  cursor: pointer;
}

.click-to-edit-enabled {
  /*background-color: #f7f9ff;*/
}
