

































































































































































































































































.el-table {
    &.divisions-table {
      tr {
        &.deactivated {
          td {
            > * {
              color: #b5b5b5;
            }
            a {
              color: #b5b5b5;
              &:hover {
                &:after{
                  border-color: #b5b5b5;
                }
              }
            }
          }
        }
      }
    }
  }
