
























































































































































































































@import "@/assets/css/project-variables.scss";

.agree-section {
  width: fit-content;

  .item {
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      background-color: rgba($--color-danger, 0.5);
      border-radius: 50%;
    }

    &.agreed {

      &::before {
        background-color: $--color-primary;
      }
    }
  }

  &__btns {
    display: inline-flex;
    align-items: center;
    padding-left: 16px;
  }

  .el-button {
    width: 24px;
    height: 24px;
    border: none;

    i {
      margin: 0;
      font-size: 14px;
    }
  }

  &__btn-check {
    color: $--color-white;
    background-color: #3D8BF4;
    border-color: #3D8BF4;
  }

  &__btn-times {
    color: $--color-white;
    background-color: rgba($--color-danger, 0.5);
  }

  &__btn-undo {
    color: $--color-white;
    background-color: $--color-warning;
  }
}
