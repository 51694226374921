























































































































































































.solution {
  .el-dialog__body {
    min-height: 150px;
    max-height: 300px;
    overflow-y: auto;
  }
}

