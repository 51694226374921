











































































































































































@import "@/assets/css/project-variables.scss";

.responsible-dialog {

  &__desc {
    margin-bottom: 24px;
  }

  &__form {

    .el-form-item {
      margin-bottom: 16px;
    }
  }
}
