














































































































































































































































































































































































































































@import "./src/assets/css/project-variables";

.schedule-table {
  border-top: 1px solid $--color-divider;

  &__block {
    margin: 0 0 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__head {
    padding: 24px 0 16px;

    &-title {
      margin: 0 4px 0 0;
      color: $--color-text-regular-4;
    }
  }

  &__row {
    padding: 16px 0;
    border-bottom: 1px solid $--color-divider;

    .edit-block {
      flex: 1;
    }
  }

  &__col {
    padding: 0 16px;
    font-size: 14px;
  }

  &__bottom {
    padding: 16px 0;

    > .el-button {
      margin-right: 24px;
    }
  }

  &__cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
  }

  &__heading {
    padding: 16px 0;
    background-color: $--color-divider-3;
    border-radius: 6px;

    .el-checkbox {
      margin-right: 24px;
    }

    .el-checkbox__label {
      font-size: 16px;
    }

    .edit-block {
      min-width: 600px;
    }
  }

  @media only screen and (max-width: 1480px) {

    &__head {
      padding-top: 16px;
      padding-bottom: 12px;
    }

    &__heading {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &__row {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &__block {
      margin-bottom: 24px;
    }
  }
}

