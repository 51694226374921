@import "./project-variables.scss";

* {
  box-sizing: border-box;

  &:before, &:after {
    box-sizing: border-box;
  }
}

h1, h2, h3, h4, h5, h6, ul {
  padding: 0;
  margin: 0 0 32px 0;
}

ul {
  list-style: none;
}

html, body {
  font-size: 16px;
}

body {
  position: relative;
  min-width: 320px;
  margin: 0;
  font-family: 'Verdana', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
  color: $--color-text-regular;
}

p {
  padding: 0;
  margin: 0 0 24px 0;
  font-size: 16px;
  line-height: 1.44;
}

b {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.44;
}

i {
  font-style: italic;
  font-size: 18px;
  line-height: 1.44;
}

h1 {
  font-size: 26px;
  font-weight: 400;
}

h2 {
  font-size: 22px;
  font-weight: 400;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

h3.light {
  font-weight: 400;
}

h4 {
  font-size: 18px;
  font-weight: 700;
}

h4.light {
  font-weight: 400;
}

h5 {
  font-size: 14px;
  font-weight: 700;
}

input {
  font-size: 14px;
  font-weight: 400;
}

button {
  font-size: 16px;
  font-weight: 400;
}

a {
  font-size: 14px;
  color: $--color-primary;
  text-decoration: none;
  transition: all .4s;
}

td {
  font-size: 14px;
  font-weight: 400;
}

.el-dialog__body,
.el-popover,
.el-table .cell {
  word-break: normal;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.el-select-dropdown.el-popper{
  max-width: 900px;
}
.el-button {
  width: auto;
  height: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12.5px 24px;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: $--color-primary;
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  background-color: $--color-white;
  border: 1px solid $--color-primary-light-6;
  transition: all .4s;

  &:hover {
    color: $--color-primary;
    background-color: $--color-primary-light-7;
    border-color: $--color-primary-light-7;
  }

  .el-icon-arrow-left::before {
    content: '';
    width: 16px;
    height: 16px;
    background-image: url("/src/assets/img/icons/arrow-left.svg");
  }

  .el-icon-arrow-left + span {
    text-transform: none;
  }

  i {

    &.fa-add-users {
      width: 18px;
      height: 18px;

      &::before {
        content: '';
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-image: url("./assets/img/icons/icon-add-users.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }
  }

  i[class^="fa"] {
    margin-right: 8px;
  }

  &.is-disabled {
    color: $--color-text-regular-6;
    background-color: $--color-white;
    border-color: $--color-secondary-gray-hover;

    &:hover {
      color: $--color-text-regular-6;
      background-color: $--color-white;
      border-color: $--color-secondary-gray-hover;
    }
  }

  &.is-plain {
    background-color: $--color-primary-light-9;
    border-color: $--color-primary-light-9;

    &.is-disabled {
      color: $--color-text-regular-6;
      background-color: $--color-secondary-gray-hover;
      border-color: $--color-secondary-gray-hover;

      &:hover {
        color: $--color-text-regular-6;
        background-color: $--color-secondary-gray-hover;
        border-color: $--color-secondary-gray-hover;
      }
    }

    &:hover {
      background-color: $--color-primary-light-7;
      border-color: $--color-primary-light-7;
      color: $--color-primary;
    }
  }

  &--medium {
    padding-top: 12.5px;
    padding-bottom: 12.5px;
  }

  &--small {
    padding-top: 8.5px;
    padding-bottom: 8.5px;
    text-transform: none;
    font-weight: 400;
  }

  &--mini {
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 400;
    text-transform: none;
  }

  &--primary {
    color: $--color-white;
    background-color: $--color-primary;
    border-color: $--color-primary;

    &.is-disabled {
      color: $--color-text-regular-6;
      background-color: $--color-secondary-gray-hover;

      &:hover {
        color: $--color-text-regular-6;
        background-color: $--color-secondary-gray-hover;
      }
    }

    &:hover {
      color: $--color-white;
      background-color: $--color-primary-hover;
      border-color: $--color-primary-hover;
    }
  }

  &--text {
    padding: 0;
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    background-color: transparent;
    border: none;
    transition: all .4s;

    i {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all .4s;

      &::before {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }

      &.el-icon-view {

        &::before {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url("./assets/img/icons/icon-eye.svg");
        }
      }

      &.el-icon-delete {

        + span {
          color: $--color-danger;
        }

        &::before {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url("./assets/img/icons/icon-trash-lines.svg");
        }
      }

      &.el-icon-users {

        &::before {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url("./assets/img/icons/icon-users.svg");
        }
      }

      &.el-icon-list-delete {

        &::before {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url("./assets/img/icons/icon-list-delete.svg");
        }
      }

      &.el-icon-list {

        &::before {
          content: '';
          width: 16px;
          height: 16px;
          background-image: url("./assets/img/icons/icon-list.svg");
        }
      }
    }

    &.is-disabled {
      color: $--color-divider;

      &:hover {
        color: $--color-divider;

        i {
          color: inherit;
        }
      }
    }

    &.el-button--small,
    &.el-button--mini {
      font-size: 14px;

      i {
        width: 14px;
        height: 14px;
        font-size: 14px;
      }
    }

    &:hover {
      color: $--color-primary-hover;
      background-color: transparent;

      i {
        color: $--color-primary-hover;
      }
    }
  }

  &--trash {

    i {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background-image: url("./assets/img/icons/icon-trash.svg");
        background-repeat: no-repeat;
        background-size: contain;
        transition: all .4s;
      }
    }

    &:hover {

      i {

        &::before {
          background-image: url("./assets/img/icons/icon-trash-hover.svg");
        }
      }
    }
  }

  &--delete {
    color: $--color-secondary-red;

    &:hover, &:hover i {
      color: $--color-secondary-red-hover;

      &::before {
        background-image: url("./assets/img/icons/icon-trash-hover.svg");
      }
    }
  }

  &--download, &--upload {
    position: relative;
    padding-left: 48px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 24px;
      width: 16px;
      height: 16px;
      background-image: url("./assets/img/icons/icon-download-cloud.svg");
      transform: translateY(-50%);
    }
  }

  &--upload {
    position: relative;
    padding-left: 48px;

    &::before {
      background-image: url("./assets/img/icons/icon-upload-cloud.svg");
    }
  }

  @media only screen and (max-width: 1480px) {
    padding: 8px 16px;
    font-size: 12px;

    i {
      font-size: 16px;
    }

    &--text {
      padding: 0;
      font-size: 14px;

      i {
        width: 14px;
        height: 14px;
        font-size: 14px;
      }

      &.el-button--small {
        padding: 0;
        font-size: 12px;

        i,
        i::before {
          font-size: 14px;
        }
      }
    }

    &--small {
      padding: 6px 12px;
      font-size: 12px;
    }

    &--mini {
      font-size: 10px;
      padding: 2px 4px;
    }

    &.el-button--download, &.el-button--upload {
      padding-left: 40px;

      &::before {
        left: 16px;
      }
    }
  }
}

.el-button [class*=el-icon-] + span {
  margin-left: 8px;
}

.el-button [class*=el-icon-]::before {
  font-size: 16px;
}

.el-button .el-icon-right {
  margin-left: 8px;
}

.el-header {
  padding: 16px 40px;
  margin: 0 0 32px;
  border-bottom: 2px solid $--color-divider-2;

  @media only screen and (max-width: 1480px) {
    padding: 8px 24px;
    margin-bottom: 24px;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;

  &--cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.el-main {
  padding: 0 40px;
  margin: 0 0 60px;

  @media only screen and (max-width: 1480px) {
    padding-right: 32px;
    padding-left: 32px;
    margin-bottom: 40px;
  }
}

.el-checkbox-group {
  display: flex;

  .el-checkbox {
    display: flex;
    align-items: center;
    margin: 0 24px 0 0;

    .el-checkbox__input {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;

      .el-checkbox__inner {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: 2px solid $--color-divider;
        border-radius: 2px;
        transition: background-color .4s, border-color .4s;

        &::after {
          content: '';
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 14px;
          height: 10px;
          border: none;
          background-image: url("./assets/img/icons/arrow-checkbox.svg");
          transform: translate3d(-50%, -50%, 0);
          transform-origin: unset;
          transition: opacity .4s;
          opacity: 0;
        }
      }

      &.is-checked {

        .el-checkbox__inner {
          background-color: $--color-primary;
          border-color: $--color-primary;

          &::after {
            opacity: 1;
          }
        }

        & + .el-checkbox__label {
          color: $--color-text-regular;
        }
      }

      &.is-disabled {

        .el-checkbox__inner {
          background-color: $--color-divider;
        }
      }

      &.is-checked.is-disabled {

        .el-checkbox__inner {
          border-color: $--color-divider;
        }
      }
    }

    .el-checkbox__label {
      display: block;
      padding: 0;
      font-size: 14px;
      line-height: 1.5;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.el-checkbox-group--vertical {
    width: 64px;
    flex-direction: column;
    padding: 24px;
    border: 1px dashed #7B61FF;
    border-radius: 5px;

    .el-checkbox {
      margin-right: 0;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.el-checkbox {
  display: flex;
  align-items: center;
  margin: 0 24px 0 0;

  .el-checkbox__input {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    .el-checkbox__inner {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: 2px solid $--color-divider;
      border-radius: 2px;
      transition: background-color .4s, border-color .4s;

      &::after {
        content: '';
        box-sizing: border-box;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 14px;
        height: 10px;
        border: none;
        background-image: url("./assets/img/icons/arrow-checkbox.svg");
        transform: translate3d(-50%, -50%, 0);
        transform-origin: unset;
        transition: opacity .4s;
        opacity: 0;
      }
    }

    &.is-checked {

      .el-checkbox__inner {
        background-color: $--color-primary;
        border-color: $--color-primary;

        &::after {
          opacity: 1;
        }
      }

      & + .el-checkbox__label {
        color: $--color-text-regular;
      }
    }

    &.is-disabled {

      .el-checkbox__inner {
        background-color: $--color-divider;
      }
    }

    &.is-checked.is-disabled {

      .el-checkbox__inner {
        border-color: $--color-divider;
      }
    }
  }

  .el-checkbox__label {
    display: block;
    padding: 0;
    font-size: 14px;
    line-height: 1.5;
  }

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 1480px) {
    font-size: 12px;

    .el-checkbox__input {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      .el-checkbox__inner {

        &::after {
          width: 12px;
          height: 8px;
          background-size: cover;
        }
      }
    }
  }
}

.el-radio-group {
  display: flex;
  align-items: center;
  vertical-align: unset;

  .el-radio {
    display: flex;
    align-items: center;
    margin: 0 24px 0 0;
    font-weight: 400;
    line-height: 1.5;

    .el-radio__input {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
      margin-right: 12px;

      .el-radio__inner {
        display: block;
        width: 100%;
        height: 100%;
        background-color: $--color-white;
        border: 2px solid $--color-divider;
        transition: border-color .4s;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          background-color: $--color-primary;
          border-radius: 50%;
        }
      }

      &.is-checked {

        .el-radio__inner {
          border-color: $--color-primary;
        }

        & + .el-radio__label {
          color: $--color-text-regular;
        }
      }
    }

    .el-radio__label {
      padding: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &.el-radio-group--vertical {
    flex-direction: column;
    align-items: flex-start;

    .el-radio {
      margin-right: 0;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.el-switch {

  &.is-disabled {
    opacity: 1;

    .el-switch__core {
      background-color: $--color-text-regular-6;
      border-color: $--color-text-regular-6;
    }

    &.is-checked {

      .el-switch__core {
        background-color: $--color-primary;
        border-color: $--color-primary;
      }
    }
  }

  @media only screen and (max-width: 1480px) {

    &__core {
      width: 32px !important;
      height: 16px;

      &::after {
        width: 13px;
        height: 13px;
      }
    }

    &__label {
      font-size: 12px;
    }

    &.is-checked {

      .el-switch__core {

        &::after {
          margin-left: -14px;
        }
      }
    }
  }
}

.el-input {
  z-index: 1;
  position: relative;
  width: 100%;
  height: auto;

  .el-input__inner {
    z-index: 1;
    width: 100%;
    height: auto;
    padding: 12px 16px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: $--color-text-regular;
    background-color: transparent;
    border: 1px solid $--color-divider;
    border-radius: 6px;
    transition: all .4s;
    cursor: pointer;

    &::placeholder {
      color: $--color-text-regular-4;
      transition: opacity .4s;
    }

    &:focus {

      &::placeholder {
        opacity: 0;
      }
    }

    &:hover {
      border-color: $--color-text-regular;
    }

    &[maxlength] {
      padding-right: 60px;
    }
  }

  &.el-input--prefix {

    .el-input__inner {
      padding-left: 48px;
    }

    .el-input__prefix {
      z-index: -1;
      position: absolute;
      top: 50%;
      left: 16px;
      width: 24px;
      height: 24px;
      transform: translate3d(0, -50%, 0);

      .el-input__icon {
        width: 100%;
        height: 100%;
        line-height: unset;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 24px;
          line-height: 1;
          cursor: pointer;
        }

        &::after {
          content: none;
        }
      }
    }
  }

  &.el-input--suffix {

    .el-input__inner {
      padding-right: 48px;
    }

    .el-input__suffix {
      position: absolute;
      top: 50%;
      right: 16px;
      width: 24px;
      height: 24px;
      color: $--color-text-regular-6;
      transform: translate3d(0, -50%, 0);
      transition: color .4s, opacity .4s;
      pointer-events: all;

      &-inner {
        display: block;
        width: 100%;
        height: 100%;
      }

      .el-input__icon {
        width: 100%;
        height: 100%;
        line-height: unset;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 24px;
          line-height: 1;
          cursor: pointer;
        }

        &::after {
          content: none;
        }

        &:hover {
          color: $--color-text-regular-4;
        }
      }
    }
  }

  &.is-focus {

    .el-input__inner {
      border-color: $--color-primary-light-6;
    }
  }

  &.is-disabled {

    input {
      color: $--color-text-regular-4;
      background-color: $--color-secondary-gray;
      border-color: $--color-divider;
    }

    &:hover {

      input {
        border-color: $--color-divider;
      }
    }
  }

  @media only screen and (max-width: 1480px) {

    .el-input__inner {
      padding: 8px 12px;
      font-size: 12px;
    }

    &.el-input--prefix {

      .el-input__inner {
        padding-left: 32px;
      }

      .el-input__prefix {
        left: 12px;
        width: 16px;
        height: 16px;

        .el-input__icon::before {
          font-size: 16px;
        }
      }
    }

    &.el-input--suffix {

      .el-input__inner {
        padding-right: 32px;
      }

      .el-input__suffix {
        right: 12px;
        width: 16px;
        height: 16px;

        .el-input__icon {

          &::before {
            font-size: 16px;
          }
        }
      }
    }
  }
}

input[type="search" i]::-webkit-search-cancel-button {
  display: none;
}

input[type="search"]:invalid {
  border-color: $--color-secondary-red;
}

input[type="search"] + .el-input__prefix + .el-input__suffix {

  .el-input__icon {

    &::before {
      content: "";
    }
  }
}

.el-select {
  width: 100%;

  .el-input__inner {

    &:focus {
      border-color: $--color-divider;
    }
  }

  &__tags {
    z-index: 2;

    .el-tag.el-tag--info {
      max-width: 85%;
    }
  }

  &__caret.el-icon-circle-close {

    &::before {
      content: "";
      transform: rotate(180deg);
    }
  }

  &--small {

    .el-input__inner {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.el-select-dropdown.el-popper[x-placement^=bottom] {
  margin-top: 6px;
  margin-bottom: 0;
  box-shadow: 1px 0px 30px 7px rgba(20, 33, 64, 0.05);
  border-radius: 6px;

  .popper__arrow {
    display: none;
  }
}

.el-select-dropdown {

  &__list {
    padding: 0;
  }

  &__item {
    height: auto;
    padding: 13.5px 16px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    transition: all .4s;

    &:first-child {
      border-radius: 6px 6px 0 0;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    &.selected {
      font-weight: 400;
      color: inherit;
    }

    &.hover {
      color: $--color-primary;
      background-color: $--color-divider-4;
    }
  }

  @media only screen and (max-width: 1480px) {

    &__item {
      padding: 8px 12px;
      font-size: 12px;
    }
  }
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.el-date-editor {

  &.el-input--prefix {

    .el-input__inner {
      padding: 12.5px 48px 12.5px 16px;
    }

    .el-input__prefix {
      display: none;
    }

    .el-input__suffix {

      .el-input__icon {

        &::before {
          content: '';
          width: 24px;
          height: 24px;
          background-image: url("./assets/img/icons/icon-calendar.svg");
        }
      }

      .el-input__icon.el-icon-circle-close {

        &::before {
          content: "";
          background-image: none;
        }
      }
    }
  }

  @media only screen and (max-width: 1480px) {

    &.el-input--prefix {

      .el-input__inner {
        padding: 8px 12px;
        font-size: 12px;
      }

      .el-input__suffix {

        .el-input__icon {

          &::before {
            width: 16px;
            height: 16px;
            background-size: cover;
          }
        }
      }
    }
  }
}

.el-date-picker.el-popper {
  margin-top: 6px;
  margin-bottom: 0;
  border: none;
  box-shadow: 1px 0px 30px 7px rgba(20, 33, 64, 0.05);
  border-radius: 6px;

  .popper__arrow {
    display: none;
  }

  .el-date-picker__header,
  .el-picker-panel__content {
    width: 100%;
    padding: 24px 20px 12px;
    margin: 0;
  }

  .el-date-picker__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .el-picker-panel__icon-btn {
    float: none;
    padding: 0;
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    color: $--color-text-regular-4;
    line-height: 1;
    transition: color .4s;

    &.el-date-picker__prev-btn {
      order: 1;
      margin-right: auto;
    }

    &.el-date-picker__next-btn {
      order: 4;
      margin-left: auto;
    }

    &:hover {
      color: $--color-black;
    }
  }

  .el-date-picker__header-label {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    color: $--color-text-regular;
    transition: color .4s;

    &:hover {
      color: $--color-primary;
    }
  }

  .el-date-picker__header-label:first-of-type {
    order: 3;
  }

  .el-date-picker__header-label:last-of-type {
    order: 2;
  }

  .el-picker-panel__content {
    padding-top: 4px;
  }

  .el-icon-d-arrow-left,
  .el-icon-d-arrow-right {
    display: none;
  }

  .el-date-table {

    tbody {

      tr {

        th {
          padding: 8px 10px;
          font-size: 14px;
          line-height: 1.5;
          color: $--color-divider;
          text-transform: uppercase;
          border-bottom: none;
        }
      }

      td {
        width: 18px;
        height: 21px;
        padding: 0;
        font-size: 14px;
        line-height: 1.5;
        font-weight: 400;
        color: $--color-text-regular-4;
        transition: color .4s;

        &.next-month,
        &.prev-month {
          color: $--color-divider;
        }

        &.today {
          color: $--color-primary;
        }

        span {
          border-radius: 4px;
        }

        &:hover {
          color: $--color-text-regular;
        }
      }
    }
  }
}

.el-menu {
  background-color: transparent;

  &.el-menu--horizontal {
    display: flex;
    border-bottom: none;

    > .el-menu-item, .el-submenu {
      position: relative;
      float: none;
      height: auto;
      padding: 0;
      margin-right: 32px;
      font-size: 16px;
      line-height: 1.5;
      color: $--color-text-regular-4;
      border-bottom: none;
      transition: color .4s;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -37px;
        width: 100%;
        height: 2px;
        background-color: $--color-primary;
        opacity: 0;
        transition: opacity .4s;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $--color-text-regular;
      }

      &.is-active {
        color: $--color-text-regular;

        &::before {
          opacity: 1;
        }
      }

      &.is-opened {

        .el-submenu__icon-arrow {
          color: $--color-text-regular-4;
        }
      }

      &__title {
        height: auto;
        display: flex;
        align-items: center;
        padding: 0;
        font-size: 16px;
        line-height: 1.5;
        color: $--color-text-regular-4;
        border-bottom: none;

        &:hover {
          color: $--color-text-regular;
        }
      }

      &__icon-arrow {
        margin: 2px 0 0 8px;
        font-weight: 600;
        color: $--color-text-regular-6;
      }
    }

    &::before,
    &::after {
      content: none;
    }
  }

  &--popup.el-menu {
    padding: 0;
    margin-top: 8px;
    background: $--color-white;
    box-shadow: 1px 0px 30px 7px rgba(20, 33, 64, 0.06);
    border-radius: 5px;

    .el-menu-item {
      height: auto;
      padding: 8px 16px;
      line-height: 1.5;
      color: $--color-text-regular-4;
      transition: background-color .4s, color .4s;

      &:first-child {
        border-radius: 5px 5px 0 0;
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }

      &:hover {
        color: $--color-primary;
        background-color: $--color-divider-4;
      }
    }
  }

  @media only screen and (max-width: 1600px) {

    &.el-menu--horizontal {

      > .el-menu-item, .el-submenu {
        margin-right: 24px;
      }
    }
  }

  @media only screen and (max-width: 1480px) {

    &.el-menu--horizontal {

      > .el-menu-item, .el-submenu {
        font-size: 14px;

        &__title {
          font-size: 14px;
        }

        &::before {
          bottom: -29px;
        }
      }
    }

    &--popup.el-menu {
      padding: 0;
      margin-top: 4px;

      .el-menu-item {
        padding: 4px 8px;
        font-size: 12px;
      }
    }
  }
}

.form-label {
  display: flex;
  flex-direction: column;

  &__title {
    margin: 0 0 8px;
    font-size: 14px;
    color: $--color-text-regular-4;
  }

  @media only screen and (max-width: 1480px) {

    &__title {
      font-size: 12px;
      margin-bottom: 4px;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .4s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.toggle-btn {

  i {
    transform: translate3d(0, 0, 0);
    transform-origin: center;
    transition: transform .4s;
  }

  &.active {

    i {
      transform: translate3d(0, 0, 0) rotate(180deg);
    }
  }
}

.el-table {

  th.el-table__cell {
    padding: 24px 0 10px;
    vertical-align: top;
    border-top: 1px solid $--color-divider;

    > .cell {
      padding: 0 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
    }

    &.is-sortable {

      > .cell {
        display: flex;
        align-items: center;
      }
    }
  }

  .caret-wrapper {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0 0 8px;
  }

  .sort-caret {
    position: static;
    width: 8px;
    height: 6px;
    height: 6px;
    margin: 0 0 2px;
    border: none;
    background-image: url("./assets/img/icons/arrow-sort-ascending.svg");
    transition: all .4s;

    &.descending {
      margin-bottom: 0;
      background-image: url("./assets/img/icons/arrow-sort-descending.svg");
    }

    &:hover {
      background-image: url("./assets/img/icons/arrow-sort-ascending-hover.svg");

      &.descending {
        background-image: url("./assets/img/icons/arrow-sort-descending-hover.svg");
      }
    }
  }

  td.el-table__cell {
    padding: 16px 0;
    vertical-align: top;
    border-bottom: 1px solid $--color-divider;

    &.is-middle {
      vertical-align: middle;
    }

    &--ellipsis {
      vertical-align: middle;
    }
  }

  .cell {
    padding: 0 12px;
    line-height: 1.5;
  }

  &::before {
    background-color: transparent;
  }

  @media only screen and (max-width: 1480px) {

    th.el-table__cell {
      padding-top: 16px;
      padding-bottom: 8px;

      > .cell {
        padding-right: 8px;
        padding-left: 8px;
        font-size: 12px;
      }
    }

    td.el-table__cell {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 12px;

      .cell {
        padding-right: 8px;
        padding-left: 8px;
      }
    }
  }
}

.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}

.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: $--color-divider-4;
}

.fa-ellipsis-v {
  color: $--color-primary-light-3;

  &:hover {
    color: $--color-primary;
  }
}

.el-tooltip__popper {
  max-width: 440px;

  &.is-light {
    background-color: $--color-primary-light-9;
    border-color: $--color-primary;

    .popper__arrow {
      background-color: $--color-primary-light-9;
      border-color: $--color-primary;
    }
  }

  @media only screen and (max-width: 1480px) {
    font-size: 12px;
    padding: 8px;
  }
}

.el-tooltip__popper.is-dark {
  box-shadow: 1px 0px 30px 7px rgba(20, 33, 64, 0.06);
  border-radius: 5px;
}

.el-form {

  &--label-top {

    .el-form-item__label {
      padding: 0;
      margin: 0 0 8px;
    }
  }
}

.el-form-item {

  &__label {
    font-size: 14px;
    line-height: 1.5;
    color: $--color-text-regular-4;
  }

  &__content {
    line-height: 1.5;
  }

  &__error {
    position: static;
    margin-top: 8px;
  }

  @media only screen and (max-width: 1480px) {
    margin-bottom: 16px;

    &__label {
      font-size: 12px;
    }
  }
}

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before {
  color: $--color-secondary-red;
}

.el-popconfirm {

  &__main {
    display: block;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
  }

  @media only screen and (max-width: 1480px) {

    &__main {
      font-size: 16px;
      margin-bottom: 12px;
    }
  }
}

.el-pagination, .el-pagination.is-background, .pagination .pagination__list.el-pagination {
  display: flex;
  align-items: center;
  padding: 0;
  font-weight: 400;

  .btn-prev,
  .btn-next {
    min-width: auto;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 24px 0 0;
    background-color: transparent;
    border-radius: unset;

    .el-icon {
      position: relative;
      width: 100%;
      height: 100%;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate3d(-50%, -50%, 0);
        background-image: url("./assets/img/icons/arrow-pagination-left.svg");
        transition: all .4s;
      }
    }

    &:hover {

      .el-icon {

        &::before {
          background-image: url("./assets/img/icons/arrow-pagination-left-hover.svg");
        }
      }
    }

    &:disabled {

      &:hover {

        .el-icon {

          &::before {
            background-image: url("./assets/img/icons/arrow-pagination-left.svg");
          }
        }
      }
    }
  }

  .btn-next {
    margin-right: 0;
    margin-left: 24px;

    .el-icon {

      &::before {
        background-image: url("./assets/img/icons/arrow-pagination-right.svg");
      }
    }

    &:hover {

      .el-icon {

        &::before {
          background-image: url("./assets/img/icons/arrow-pagination-right-hover.svg");
        }
      }
    }

    &:disabled {

      &:hover {

        .el-icon {

          &::before {
            background-image: url("./assets/img/icons/arrow-pagination-right.svg");
          }
        }
      }
    }
  }

  .el-pager {
    display: flex;
    align-items: center;
    vertical-align: unset;

    li {
      min-width: unset;
      height: auto;
      display: flex;
      padding: 0;
      margin: 0 16px 0 0;
      font-size: 14px;
      line-height: 1.5;
      color: $--color-text-regular-4;
      vertical-align: unset;
      background: transparent;
      transition: color .4s;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: $--color-primary;
      }

      &.active {
        color: $--color-primary;
      }

      &.more {

        &::before {
          content: '...';
          font-size: 14px;
          line-height: 1.5;
          color: $--color-text-regular-4;
          transition: color .4s;
        }

        &:hover {

          &::before {
            color: $--color-text-regular;
          }
        }
      }
    }
  }
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: transparent;
}

.el-dropdown {

  &-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $--color-primary;
    transition: color .4s;

    .el-icon-arrow-down {
      color: $--color-primary-light-6;
      transition: color .4s;
    }

    &:hover {
      color: $--color-primary-hover;

      .el-icon-arrow-down {
        color: $--color-black;
      }
    }
  }
}

.el-dropdown-menu {
  padding: 8px 0;
  background: $--color-white;
  box-shadow: 1px 0 30px 7px rgba(20, 33, 64, 0.06);
  border-radius: 5px;

  &__item {
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;
    color: $--color-text-regular-4;
    transition: color .4s, background-color .4s;

    &:hover {
      color: $--color-primary;
      background-color: $--color-divider-4;
    }
  }

  .popper__arrow {
    display: none;
  }
}

.el-popper-table .ellipsis-menu-slot {

  > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.title-edit {

  .edit-block {
    font-size: 18px;

    .click-to-edit-div {

      .edit-block__value {
        align-items: center;

        span:not([class="placeholder"]) {
          font-size: 18px;

          @media only screen and (max-width: 1480px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.input-helper {

  i.fas.fa-info-circle {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-image: url("./assets/img/icons/icon-info.svg");
      background-size: contain;
      background-position: center;
    }
  }
}

i {

  &.fas.fa-trash {

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      background-image: url("./assets/img/icons/icon-trash-blue.svg");
    }
  }
}

.test {

  .el-timeline-item__node.el-timeline-item__node--info {
    background-color: transparent;

    i.el-timeline-item__icon {
      width: 100%;
      height: 100%;
      background-image: url("./assets/img/icons/icon-unanswered.svg");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      &::before {
        content: none;
      }
    }
  }
}

.pagination__amount .el-button.el-button--default:hover,
.pagination__amount .el-button.el-button--default:focus {
  color: $--color-primary;
}

.el-dialog {

  &__title {
    line-height: 1.5;
  }

  @media only screen and (max-width: 1480px) {

    &__header {
      padding: 16px;
      padding-bottom: 8px;
    }

    &__title {
      font-size: 16px;
      line-height: 1.5;
    }

    &__body {
      padding: 24px 16px;
      font-size: 12px;
    }

    &__footer {
      padding: 16px;
      padding-bottom: 8px;
    }
  }
}

.el-scrollbar__bar {
  opacity: 1;
}

.text-min {
  font-size: 14px;
}

.btn-wrap {
  display: flex;
  align-items: center;
}

.test-img {
  width: 240px;
  height: 100%;

  img.el-image__inner {
    display: block;
    object-fit: cover;
    object-position: center;
  }
}

.el-notification__content {
  text-align: left;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
}

@media only screen and (max-width: 1480px) {

  html, body {
    font-size: 14px;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 12px;
  }

  p {
    font-size: 12px;
    margin-bottom: 12px;
  }

  b {
    font-size: 14px;
  }

  .el-link {
    font-size: 12px;
  }
}
