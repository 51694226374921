































































































































































































































































































































































































































































































































































































































































@import "./src/assets/css/project-variables";

.test {
  border-top: none;

  &__amount {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 24px;
    border-bottom: none;

    &-item {
      display: flex;
      align-items: center;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__main {
    border-top: 1px solid $--color-divider;
  }

  &__collapse {

    .questions-timeline {
      border-bottom: none;
    }

    .el-collapse-item.is-active {
      padding-bottom: 0;
    }
  }

  &__content {

    &-title {
      font-weight: 400;
      margin-bottom: 32px;
    }

    &-img {
      margin-bottom: 32px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    &-list {
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 20px 16px;
      margin-bottom: 16px;
      background-color: $--color-white;
      border: 1px solid $--color-divider;
      border-radius: 6px;

      .el-radio__input {
        margin-right: 8px;
      }

      .answer {
        margin-bottom: 0;
        white-space: normal;
      }

      .answer-img {
        width: 120px;
        height: 120px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-btns {
      display: flex;
      align-items: center;
      margin-top: 40px;

      button {
        margin-left: 0;
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__check {
    padding: 24px 0 0;

    &-title {
      margin-bottom: 16px;
    }

    &-desc {
      margin-bottom: 8px;
      font-size: 14px;
      color: $--color-text-regular-4;
    }

    &-item {
      display: flex;
      align-items: center;
      margin: 0 0 16px;

      &__select {
        flex: 1;
        margin-right: 16px;
      }

      &__delete {
        flex-shrink: 0;
      }
    }
  }

  &__finished {

    &-title {
      margin-bottom: 24px;
    }

    &-desc {
      margin-bottom: 8px;
    }

    &-form {

      .el-textarea {
        margin-bottom: 16px;
      }
    }
  }
}

.select-question-button {
  padding: 0;
}

.select-question-button.active{
  font-weight: bold;
}

.el-divider.el-divider--horizontal {
  margin: 10px 0;
}

.player-section-collapse .el-collapse-item__header {
  line-height: unset;
}

.memo {

  p {
    text-indent: 24px;
  }

  ol {
    list-style-type: none;
    counter-reset: item;

    li {

      &::before {
        content: counter(item) ') ';
        counter-increment: item;
      }
    }
  }
}

.question-answers-hint {
  color: red;
  font-style: italic;
  margin-bottom: 5px;
}

