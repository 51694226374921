













































































































































































































































































@import "@/assets/css/project-variables.scss";

.link {
  color: $--color-primary;
  cursor: pointer;
}

.project-desc {
  margin-bottom: 8px;
}

