


























































































































.edit-header {

  .edit-block {

    .edit-block__value {
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
      }
    }
  }
}

