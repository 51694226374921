










































































































































@import "./src/assets/css/project-variables";

.empty {
  margin: 30px 0;
}

.filters {
  padding: 0 0 32px;
  margin: 0 0 24px;
  border-bottom: 1px solid $--color-divider;

  &__item {
    margin-right: 24px;
  }
}

