





































































































































































































































@import "@/assets/css/project-variables.scss";

.library {
  padding: 24px 0 24px 0;
  border-top: 1px solid $--color-divider-2;
  border-right: 1px solid $--color-divider-2;
  border-bottom: 1px solid $--color-divider-2;
  border-radius: 0 6px 6px 0;

  .el-tree {
    max-height: 60vh;
    margin: 0 0 16px;
    padding-right: 24px;
    overflow: auto;

    .el-tree-node {
      margin: 0 0 16px;

      &__content {
        height: auto;
      }

      &__expand-icon {
        margin-right: 8px;
        font-weight: 700;
      }

      &__children {

        .icon-folder_text {
          max-width: 170px;

          @media only screen and (max-width: 1360px) {
            max-width: 140px;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .folders-tree-node {
      position: relative;

      .icon-folder {
        flex: 1;
        display: flex;
        align-items: center;

        i {
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $--color-primary;
        }

        .icon-folder_text {
          width: 100%;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;

          .el-tooltip {
            display: block;
            max-width: 220px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .manageFolderButtonsSpan {
        position: absolute;
        top: 50%;
        right: 0;
        flex-shrink: 0;
        transform: translateY(-50%);

        .el-button {
          width: 18px;
          height: 18px;
          margin-right: 8px;

          i {
            margin: 0;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1480px) {
    padding: 16px 0 16px 0;

    .el-tree {

      .el-tree-node {
        margin-bottom: 8px;

        &__expand-icon {
          margin-right: 0;
        }
      }

      .folders-tree-node {

        .icon-folder {

          i {
            width: 16px;
            height: 16px;
            font-size: 16px;
          }

          .icon-folder_text {
            font-size: 12px;

            .el-tooltip {
              max-width: 180px;
            }
          }
        }
      }
    }
  }
}

.folders-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*font-size: 14px;*/
  /*padding-right: 8px;*/
}

.folders-tree-node .manageFolderButtonsSpan {
  display: none;
}

.folders-tree-node:hover .manageFolderButtonsSpan {
  display: block;
}
