


























































































































































































































































.pilot-assessment-spk-table {

  &__header {
    margin-bottom: 20px;
    color: #909399;
    font-size: 16px;
  }
  &__row {
    font-size: 16px;
    margin: 40px;
  }
  .el-collapse {
    border: none;
  }

  .el-collapse-item__header {
    background: #f5f5f5;
    margin-left: 49px;
    border: none;
    padding-left: 30px;
  }

  button {
    color: #909399;
    border-color: #909399;
    padding: 7px 15px;
    &:hover {
      color: #909399;
      background-color: #fff;
      border-color: #cecfd1;
    }
  }
  &__icon {
    font-size: 30px;
    &.el-icon-success {
      color: #68b168;
    }
    &.el-icon-error {
      color: #e96666;
    }
  }


}

