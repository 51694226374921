

























































































































































































@import "@/assets/css/project-variables.scss";

.el-input__suffix .el-checkbox span.el-checkbox__label {
  font-size: 12px;
}
